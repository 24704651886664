// import React, { lazy } from "react";
// import ReactDOM from "react-dom";
// import "./assets/scss/style.scss";

// const App = require("./app").default;

// ReactDOM.render(<App />, document.getElementById("root"));
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "./assets/scss/style.scss";
import "./assets/scss/all/custom/dms.css";
import "./components/react-table/react-table.css";
import Spinner from "./views/spinner/Spinner";

const App = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import("./app")), 0);
    })
);

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <App />
  </Suspense>,
  document.getElementById("root")
);
